import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "legal-notice"
    }}>{`Legal Notice`}</h1>
    <h2 {...{
      "id": "company"
    }}>{`Company`}</h2>
    <p>{`Château de Morey (France) (hereinafter referred to as “the Company”) is a Sole Proprietorship, registered in the Nancy Trade and Companies Register under number A434743225. The registered office is located at 19 rue saint pierre, 54610 Morey, the intra-community VAT number is FR8543474322200014.
The Company’s telephone number is : 03 83 31 50 98](tel:+33383815098).
Director of publication: `}<a parentName="p" {...{
        "href": "https://fr.linkedin.com/in/johann-karst-6a5a48a2",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`Johann Karst`}</a></p>
    <h2 {...{
      "id": "lodging"
    }}>{`Lodging`}</h2>
    <p>{`The site `}<a parentName="p" {...{
        "href": "https://www.chateaudemorey.fr/",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`www.chateaudemorey.fr`}</a>{` is hosted by Netlify, a registered trademark of “Netlify, Inc.”, a private company governed by the tax code of the United States of America.
610 22nd Street, Suite 315,
San Francisco, CA 94107
Website: `}<a parentName="p" {...{
        "href": "https://www.netlify.com.",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`https://www.netlify.com.`}</a>{`
The website `}<a parentName="p" {...{
        "href": "http://www.chateaudemorey.fr",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`www.chateaudemorey.fr`}</a>{` hereinafter referred to as “the Site”.`}</p>
    <h2 {...{
      "id": "privacy-policy"
    }}>{`Privacy Policy`}</h2>
    <p>{`The information collected in the forms is recorded in a computerized file by the company Château de Morey in order, in particular, to subscribe to one of the offers for bed and breakfast or room rental, to request information from the Company, to submit comments about the site or the offers. This nominative personal information is necessary for the Company to properly process the Internet user’s request.
The data collected will only be communicated to the following recipients: `}<a parentName="p" {...{
        "href": "https://www.typeform.com/",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`TypeForm Inc`}</a>{`, `}<a parentName="p" {...{
        "href": "https://mailchimp.com/",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`Mailchimp Inc`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.chateaudemorey.fr/",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`Château de Morey`}</a>{`.
The data is kept for at least the duration of the exchange between the Internet User and the Company, and for a maximum of 5 years.
You can access your personal data, rectify them and request their deletion.
Consult the website `}<a parentName="p" {...{
        "href": "https://www.cnil.fr/",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`cnil.fr`}</a>{` for more information on your rights.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      